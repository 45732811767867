
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Constant from '@/utils/constant';
import Common from '@/utils/common';
import moment from 'moment';

import TableFiledCeckBox from '@/components/common/TableFiledCeckBox.vue';
import TableRowSelect from '@/components/common/TableRowSelect.vue';

@Component({
  name: 'DepartureResult',
  components: {
    TableFiledCeckBox,
    TableRowSelect,
  },
})
export default class DepartureResultVue extends Vue {
  @Prop() headers: Array;
  @Prop() data: Array;

  IPP: number = 15
  page: number = 1
  pageLength: number = 0

  async pageChanged(page: number): Promise<void> {
    this.page = page;
  }
  async changeIPP(IPP: number): Promise<void> {
    this.IPP = IPP;
    this.pageLength = Math.ceil(this.data.length / this.IPP)
  }
  numbering(number): string {
    return number.toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  요청일자(date: string) {
    return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : ''
  }

  async created(): Promise<void> {
    // await this.getDepartureList();

    this.pageLength = Math.ceil(this.data.length / this.IPP)
  }

  detail(item: any) {
    console.log(item)
    window.open("/#/stock_request/detail?id=" + item.생성일자, "RequestPopupWin", "width=1265,height=580")
  }
}
