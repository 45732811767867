
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import moment from 'moment';

import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';

import Common from '@/utils/common';
import RequestInput from './RequestInput.vue';
import RequestResult from './RequestResult.vue';
import api from '@/api'

@Component({
  name: 'RequestList',
  components: {
    RequestInput,
    RequestResult,
  },
})
export default class  RequestListVue extends Vue {
  // get pageLength(): number {
  //   return (Math.ceil(this.$store.state.common.userInfo.totalCount / this.userInfo.limit ));
  // }
  get userInfo(): any {
    return this.$store.getters.getUser;
  }
  isLoading: any = false;

  today: any = '';
  start_date: any = '';
  end_date: any = '';

  menu1: boolean = false;
  menu2: boolean = false;

  expanded: any = [];
  headers: Array<Object> = [{ 
      text: '요청일자', 
      value: '생성일자', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '지역', 
      value: '출고처.지역', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '요청사', 
      value: '출고처.이름', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '요청자', 
      value: '유저.이름', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '품목명', 
      value: '제품명', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '요청수량', 
      value: '수량', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '상태', 
      value: '상태', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '처리 담당자', 
      value: '유저.이름', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '승인수량', 
      value: '승인수량', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '출고완료일', 
      value: '입출고_기록', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '특이사항', 
      value: '특이사항', 
      width: 300,
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: 'MID', 
      value: 'MID', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '상세',
      value: 'action', 
      align: 'center', 
      sortable: false, 
      width: 80,
      class: 'font-weight-bold caption header-style' 
    },
  ];
  row_data: Array<Object> = []
  data: Array<Object|null> = []

  @Watch('end_date')
  @Watch('start_date')
  async changeDate(): Promise<void> {
    console.log(this.end_date, this.start_date)
    this.data = []
    this.row_data.forEach(el => {
      moment(el.생성일자, "YYYY-MM-DD") <= moment(this.end_date, "YYYY-MM-DD") &&
      moment(el.생성일자, "YYYY-MM-DD") >= moment(this.start_date, "YYYY-MM-DD") ? this.data.push(el) : null
    })
    console.log(this.data)
  }
  async openPopup(path: string): Promise<void> {
    const RequestPopupWin:Window = await window.open("/#/stock_request/in?", "RequestPopupWin", "width=1000,height=405") ?? new Window
    const it = this
    RequestPopupWin.onunload = async function() {
      
      it.isLoading = false
      const res = await api.출고요청.SHOW_ALL()

      console.log("출고요청:", res)

      it.row_data = res.message
      it.data = it.row_data

      it.isLoading = true
    }

  }

  async changeFilter(filter: any): Promise<void> {
    console.log("change", filter)
    this.data = []
    this.row_data.forEach((el: any) => {
      if(filter.요청자 != null && el.유저.이름 != filter.요청자){
        return
      } else if(filter.상태 != "ALL" && el.상태 != filter.상태) {
        return
      } else if(filter.요청사 != null && el.출고처.이름 != filter.요청사) {
        return
      }  else if(filter.지역 != null && el.출고처.지역 != filter.지역) {
        return
      }  else if(filter.품목명 != "ALL" && el.제품명 != filter.품목명) {
        return
      } else {
        this.data.push(el)
      }
    })
  }

  async created(): Promise<void> {
    // console.log(this.userInfo.id)

    this.today = Common.todayDate();

    this.start_date = Common.todayDate(30);
    this.end_date = Common.todayDate();
    this.$store.commit('MENU_ACTIVE', { value: 5 });
    this.$store.commit('SET_TITLE',  { title: '출고요청 관리', icon: 'receipt_long' });
    const res = await api.출고요청.SHOW_ALL()

    console.log("출고요청:", res)

    this.row_data = res.message
    this.data = this.row_data

    this.isLoading = true
  }
  
  s2ab(s) {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
  }
  async writeXLSX(): Promise<void> {
    let wb = XLSX.utils.book_new();

    // 문서 속성세팅 ( 윈도우에서 엑셀 오른쪽 클릭 속성 -> 자세히에 있는 값들
    wb.Props = {
        Title: "출고요청 내역 "+this.start_date+" ~ "+this.end_date,
        Subject: "",
        Author: "InterPass",
        Manager: "김어진",
        Company: "InterPass",
        Category: "ERP Data",
        Keywords: "출고 요청",
        Comments: "",
        LastAuthor: "",
        CreatedDate: new Date()
    };
    wb.SheetNames.push("sheet 1");
    let wsData = this.data.map(el => {
      return {
        요청일자: el.생성일자,
        지역: el.출고처.지역,
        요청사: el.출고처.이름,
        요청자: el.유저.이름,
        품목명: el.제품명,
        요청수량: el.수량,
        상태: el.상태,
        '처리 담당자': el.유저.이름,
        승인수량: el.승인수량,
        출고완료일: el.입출고_기록,
        특이사항: el.특이사항
      }
    });
    // wsData.unshift(["품목명", "사용구분", "출고처", "지역", "담당자", "연락처", "주소", "비고"]);
    const ws = XLSX.utils.json_to_sheet(wsData);
    wb.Sheets["sheet 1"] = ws;
    const wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    console.log("download")
    const res = saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), '출고요청내역_'+this.start_date+'_'+this.end_date+'.xlsx');
    console.log(res)
  }
}
